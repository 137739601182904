import apiService from "./api";

const securityBaseUrl = process.env.REACT_APP_BASE_URL;

const editCompanyInfoService = async (publicId: any, body: any) => {
  const url = `/onboarding/${publicId}`;
  const method = "put";
  return apiService(url, method, body, true, {}, {});
};

const getCompanyInfoByIdService = async (publicId: any) => {
  const url = `/onboarding/${publicId}`;
  const method = "get";
  return apiService(url, method, null, true, {}, {});
};

const getCompanyAllNotificationSettingService = async () => {
  const url = `/settings/notification`;
  const method = "get";
  return apiService(url, method, null, true, {}, {});
};

const getCompanyNotificationSettingService = async (staffId: any) => {
  const url = `/settings/notification/${staffId}`;
  const method = "get";
  return apiService(url, method, null, true, {}, {});
};

const createCompanyNotificationSettingService = async (data: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/settings/notification`;
  const method = "post";
  return apiService(url, method, data, true, headers, {});
};
const createRoleName = async (data: any) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/roles/`;
  const method = "post";
  return apiService(url, method, data, true, headers, {});
};
const deleteRoleName = async (roleName: string) => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/roles?roleName=${roleName}`;
  const method = "delete";
  return apiService(url, method, {}, true, headers, {});
};
const assignPermissionsToRole = async (data: any, roleId: string) => {
  const headers = {
    "Content-Type": "application/json",
  };
  console.log("roleId", roleId);
  const url = `/roles/${roleId}/permissions`;
  const method = "post";
  return apiService(url, method, data, true, headers, {});
};
const getAllPermissions = async () => {
  const headers = {
    "Content-Type": "application/json",
  };
  const url = `/roles/permissions`;
  const method = "get";
  return apiService(url, method, {}, true, headers, {});
};

const editCompanyNotificationSettingService = async (
  staffId: any,
  body: any
) => {
  const url = `/settings/notification/${staffId}`;
  const method = "put";
  return apiService(url, method, body, true, {}, {});
};

const getLoggedInDeviceService = async () => {
  const headers = {
    "Content-Type": "application/json",
  };

  const url = `personnel/all-current-sessions`;
  const method = "get";
  return apiService(url, method, null, true, headers, {}, securityBaseUrl);
};

const endDeviceSessionService = async (sessionsId: any) => {
  const url = `personnel/all-current-logins/${sessionsId}`;
  const method = "delete";
  return apiService(url, method, null, true, {}, {}, securityBaseUrl);
};

export {
  editCompanyInfoService,
  endDeviceSessionService,
  getCompanyInfoByIdService,
  getLoggedInDeviceService,
  getCompanyNotificationSettingService,
  editCompanyNotificationSettingService,
  createCompanyNotificationSettingService,
  getCompanyAllNotificationSettingService,
  createRoleName,
  getAllPermissions,
  assignPermissionsToRole,
  deleteRoleName,
};
