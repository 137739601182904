import { SidebarCenterProps, SidebarTopProps } from "types";
import { ReactComponent as IconHome } from "assets/icons/IconHome.svg";
import { ReactComponent as IconTask } from "assets/icons/IconTask.svg";
import { ReactComponent as IconFramework } from "assets/icons/IconFramework.svg";
import { ReactComponent as IconEvidenceHub } from "assets/icons/IconEvidenceHub.svg";
import { ReactComponent as IconPolicy } from "assets/icons/IconPolicy.svg";
import { ReactComponent as IconCusTemplates } from "assets/icons/IconCusTemplates.svg";
import { ReactComponent as IconAudit } from "assets/icons/IconAudit.svg";
import { ReactComponent as IconEvidence } from "assets/icons/IconEvidence.svg";
import { ReactComponent as IconRiskAssessment } from "assets/icons/IconRiskAssessment.svg";
import { ReactComponent as IconRiskTreatment } from "assets/icons/IconRiskTreetment.svg";
import { ReactComponent as IconRiskRegister } from "assets/icons/IconRiskRegister.svg";
import { ReactComponent as IconRiskReport } from "assets/icons/IconRiskReport.svg";
import { ReactComponent as IconRiskVendor } from "assets/icons/IconRiskVendor.svg";
import { ReactComponent as IconRiskAsset } from "assets/icons/IconRiskAsset.svg";
import { ReactComponent as IconGovernorPersonal } from "assets/icons/IconGovernorPersonal.svg";
import { ReactComponent as IconGovernorComputer } from "assets/icons/IconGovernorComputer.svg";
import { ReactComponent as IconGovernorSettings } from "assets/icons/IconGovernorSettings.svg";
import { getTokenFromLocalStore } from "utils";
import { jwtDecode } from "jwt-decode";

const token = (getTokenFromLocalStore("access_token") as string) || "";

let decodedToken: { modules: string[] } | null = null;

if (token && token.includes(".")) {
  try {
    decodedToken = jwtDecode<{ modules: string[] }>(token);
  } catch (error) {
    console.error("Invalid token:", error);
    decodedToken = null;
  }
}

export const topSidebarData: SidebarTopProps[] = [
  {
    title: "Dashboard",
    icon: IconHome,
    link: "/",
    module: "DASHBOARD",
  },
  {
    title: "Task",
    icon: IconTask,
    link: "/task",
    module: "TASK_MANAGEMENT",
  },
  {
    title: "Assets",
    link: "/assets",
    icon: IconRiskAsset,
    module: "ASSET_MANAGEMENT",
  },
];

export const allRoutes: SidebarCenterProps = [
  {
    parentTitle: {
      title: "COMPLIANCE",
      paths: [
        {
          title: "Frameworks",
          link: "/frameworks",
          icon: IconFramework,
          shouldOpenSidebar: true,
          sideBarPaths: [],
          roles: [
            "APPROVER",
            "INTERNAL_AUDITOR",
            "REVIEWER",
            "SUPER_ADMIN",
            "PARTNER",
          ],
          module: "COMPLIANCE",
        },

        {
          title: "Policy Center",
          link: `/policy-center/`,
          icon: IconPolicy,
          shouldOpenSidebar: true,
          sideBarPaths: [],
          roles: [
            "APPROVER",
            "INTERNAL_AUDITOR",
            "REVIEWER",
            "SUPER_ADMIN",
            "PARTNER",
          ],
          module: "POLICY_CENTER",
        },
        {
          title: "Customized Templates",
          link: `/customized-templates/`,
          icon: IconCusTemplates,
          shouldOpenSidebar: true,
          sideBarPaths: [],
          roles: [
            "APPROVER",
            "INTERNAL_AUDITOR",
            "REVIEWER",
            "SUPER_ADMIN",
            "PARTNER",
          ],
          module: "CUSTOMIZED_TEMPLATE",
        },
        {
          title: "Evidence Hub",
          link: `/evidence-hub`,
          icon: IconEvidenceHub,
          shouldOpenSidebar: true,
          sideBarPaths: [],
          roles: [
            "APPROVER",
            "INTERNAL_AUDITOR",
            "REVIEWER",
            "SUPER_ADMIN",
            "PARTNER",
          ],
          module: "EVIDENCE_HUB",
        },
        {
          title: "Report",
          link: "/compliance-report",
          icon: IconRiskReport,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "RISK_ASSESSMENT",
        },
      ],
    },
  },
  {
    parentTitle: {
      title: "AUDIT HUB",
      paths: [
        {
          title: "Audit",
          link: "/audit",
          icon: IconAudit,
          shouldOpenSidebar: false,
          roles: ["SUPER_ADMIN", "INTERNAL_AUDITOR", "PARTNER"],
          module: "AUDIT_HUB",
        },
        {
          title: "Evidence",
          link: "/evidence",
          icon: IconEvidence,
          shouldOpenSidebar: true,
          sideBarPaths: [],
          roles: ["SUPER_ADMIN", "INTERNAL_AUDITOR", "PARTNER"],
          module: "EVIDENCE_HUB",
        },
      ],
    },
  },
  {
    parentTitle: {
      title: "Risk",
      paths: [
        {
          title: "Risk Register",
          link: "/risk-register",
          icon: IconRiskRegister,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "RISK_REGISTER",
        },
        {
          title: "Risk Assessment",
          link: "/risk-assessment",
          icon: IconRiskAssessment,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "RISK_ASSESSMENT",
        },
        {
          title: "Risk Treatment",
          link: "/risk-treatment",
          icon: IconRiskTreatment,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "RISK_TREATMENT",
        },
      ],
    },
  },
  {
    parentTitle: {
      title: "Governance",
      paths: [
        {
          title: "Personnel",
          link: "/personnel",
          icon: IconGovernorPersonal,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "PERSONNEL",
        },
        {
          title: "Employee Training",
          link: "/employee-training",
          icon: IconGovernorComputer,
          roles: [
            "SUPER_ADMIN",
            "APPROVER",
            "INTERNAL_AUDITOR",
            "REVIEWER",
            "PARTNER",
          ],
          module: "TRAINING",
        },
        {
          title: "Company Settings",
          link: "/company-settings",
          icon: IconGovernorSettings,
          roles: ["SUPER_ADMIN", "PARTNER"],
          module: "COMPANY_SETTINGS",
        },
      ],
    },
  },
];

export const sidebarTop = topSidebarData.filter((item) =>
  decodedToken?.modules.includes(item.module.toUpperCase().replace(" ", "_"))
);

export const sidebarCenter: SidebarCenterProps = allRoutes
  .map((section) => ({
    parentTitle: {
      ...section.parentTitle,
      paths: section.parentTitle.paths.filter((path) =>
        decodedToken?.modules.includes(
          path.module.toUpperCase().replace(" ", "_")
        )
      ),
    },
  }))
  .filter((section) => section.parentTitle.paths.length > 0);
