// @ts-nocheck
import axios from "axios";
import {
  getTokenFromLocalStore,
  clearAll,
  checkTokenExpiry,
  saveKeyToLocalStore,
  clearKey,
} from "utils";
import { refreshTokenService } from "./auth";
import { toast } from "sonner";
interface IMethod {
  method: "get" | "put" | "delete" | "post";
}
axios.defaults.headers.post["Content-Type"] =
  "application/x-www-form-urlencoded";

export const refreshAccessToken = async () => {
  try {
    const refreshTkn = getTokenFromLocalStore("refresh_token");
    if (!refreshTkn) {
      throw new Error("No refresh token found");
    }

    const response = await refreshTokenService(refreshTkn);
    const { accessToken, refreshToken } = response.data;

    saveKeyToLocalStore("access_token", accessToken);
    saveKeyToLocalStore("refresh_token", refreshToken);

    return accessToken;
  } catch (error) {
    let errorMessage = "Error refreshing access token";

    if (axios.isCancel(error)) {
      errorMessage = "Request canceled";
    } else if (error.code === "ECONNABORTED") {
      errorMessage = "Request timed out";
    } else if (error && !error.response) {
      errorMessage = "Network error";
    } else if (error && error.response) {
      const responseData = error.response.data as {
        error?: string;
        error_description?: string;
      };

      if (responseData && responseData.error === "invalid_grant") {
        errorMessage = responseData.error_description || "Token is not active";
        clearAll(true);
      } else if (error.response.status === 400) {
        errorMessage = "Bad Request: Invalid syntax.";
        toast.error(errorMessage);
        clearAll(true);
      } else {
        switch (error.response.status) {
          case 401:
            errorMessage = "Unauthorized: Invalid credentials.";
            break;
          case 403:
            toast.error("Session timed out, redirecting...");
            const currentPath = window.location.href;
            saveKeyToLocalStore("redirect_after_login", currentPath);
            clearKey("access_token");
            clearKey("refresh_token");

            setTimeout(() => {
              window.location.href = "/auth/session-timeout";
            }, 600);
            break;
          case 404:
            errorMessage = "Not Found: Resource not found.";
            break;
          case 500:
            errorMessage = "Internal Server Error.";
            break;
          case 503:
            errorMessage = "Service Unavailable.";
            break;
          default:
            errorMessage = error.response.data?.message || errorMessage;
        }
        toast.error(errorMessage);
      }
    } else if (error instanceof Error) {
      errorMessage = error.message;
    }

    throw error;
  }
};

const apiService = async (
  url: string,
  method: IMethod["method"],
  requestData?: any,
  protectedRoute: boolean = false,
  headers: any = {
    "Content-Type": "application/json",
  },
  params?: any,
  // baseURL: string = "https://439d-105-113-100-228.ngrok-free.app" || "",
  baseURL: string = process.env.REACT_APP_BASE_URL || "",
  responseType?: "json" | "blob" = "json"
) => {
  let token = getTokenFromLocalStore<string>("access_token");
  if (protectedRoute) {
    if (checkTokenExpiry(token)) {
      try {
        token = await refreshAccessToken();
      } catch (error) {
        // console.error("Error refreshing access token:", error);
        return;
      }
    }
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    axios.defaults.headers.common["Authorization"] = "";
  }

  try {
    const { data } = await axios({
      method,
      url,
      data: requestData,
      baseURL,
      headers,
      params,
      responseType,
    });
    return data;
  } catch (error) {
    console.error("API request failed:", error);
    throw error;
  }
};
export default apiService;
